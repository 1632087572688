/* stylelint-disable-next-line at-rule-no-unknown */
@tailwind base;
/* stylelint-disable-next-line at-rule-no-unknown */
@tailwind components;
/* stylelint-disable-next-line at-rule-no-unknown */
@tailwind utilities;

/* Global Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Ensure modals appear above other elements */
.ReactModal__Overlay {
  z-index: 10002 !important;
}

/* Smooth scrolling behavior */
html {
  scroll-behavior: smooth;
}

/* Remove default focus outline for better aesthetics */
button:focus, input:focus, select:focus {
  outline: none;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d;
}

::-webkit-scrollbar-thumb {
  background: #FFD700;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #FFAA00;
}

/* Style for navigation sub-items */
.nav-sub-item {
  padding-left: 2rem; /* Indent sub-items */
  font-size: 0.875rem; /* Slightly smaller text */
  transition: background-color 0.3s ease;
}

.nav-sub-item:hover {
  background-color: #4a5568; /* Match hover effect of other buttons */
}

/* Ensure the sidebar is scrollable */
.nav-container {
  overflow-y: auto;
}

/* Mobile-specific styles for navigation */
@media (max-width: 640px) {
  .nav-container {
    z-index: 10001 !important;
    width: 100%;
    max-width: 300px;
  }
  .nav-items {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-items > div {
    width: 100%;
  }
  .nav-sub-items {
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    display: block;
  }
  .nav-sub-item {
    width: 100%;
    padding-left: 2rem; /* Ensure indentation on mobile */
    font-size: 0.875rem;
  }
}

/* Style for the winners marquee container */
.winners-marquee-container {
  position: sticky;
  bottom: 0;
  background-color: #1f2937; /* Match sidebar background */
  padding: 0.5rem 1rem;
  border-top: 1px solid #374151;
}

/* Style for the winners marquee */
.winners-marquee {
  white-space: nowrap;
  overflow: hidden;
}

/* Ensure the marquee items are evenly spaced */
.winners-marquee span {
  display: inline-block;
  margin-right: 1rem;
}

/* Marquee Animation */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: marquee 15s linear infinite;
}

/* Pause animation on hover */
.group:hover .group-hover\:paused {
  animation-play-state: paused;
}