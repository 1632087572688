@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Ensure modals appear above other elements */
.ReactModal__Overlay {
  z-index: 10002 !important;
}

/* Smooth scrolling behavior */
html {
  scroll-behavior: smooth;
}

/* Remove default focus outline for better aesthetics */
button:focus, input:focus, select:focus {
  outline: none;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d;
}

::-webkit-scrollbar-thumb {
  background: #FFD700;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #FFAA00;
}

/* Glow effect for text */
.glow-text {
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.8);
}

/* Interactive hover effect */
.interactive {
  transition: all 0.3s ease;
}

.interactive:hover {
  transform: translateY(-2px);
}

/* Dynamic border animation */
.dynamic-border {
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  background-clip: padding-box;
}

.dynamic-border:hover {
  border-color: rgba(255, 215, 0, 0.5);
}

.dynamic-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 215, 0, 0.5), transparent);
  animation: borderShine 2s infinite;
}

@keyframes borderShine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

/* Glow effect for cards */
.card-glow {
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
}

/* Pulse animation for cards */
.card-pulse {
  animation: cardPulse 2s infinite;
}

@keyframes cardPulse {
  0% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 25px rgba(255, 215, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  }
}

/* Pulse animation for the latest result */
.pulse-latest {
  animation: pulseLatest 1.5s infinite;
}

@keyframes pulseLatest {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

/* Pulse glow animation for elements */
.animate-pulse-glow {
  animation: pulse-glow 2s infinite ease-in-out;
}

@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  }
}

/* Neon effect for elements */
.neon-effect {
  box-shadow: 0 0 10px #FFD700, 0 0 20px #FFD700, 0 0 30px #FFD700;
}

/* Chart container styles */
.chart-container {
  min-height: 300px;
  max-height: 400px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Scroll snap for horizontal scrolling */
.scroll-snap-x {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.scroll-snap-x > * {
  scroll-snap-align: start;
}

/* Recent Results List - Prevent overflow and ensure bottom alignment */
.recent-results-list {
  max-height: 200px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Ensure recent results items are properly sized */
.recent-results-list > * {
  flex: 0 0 auto;
}

/* Custom scrollbar for recent results */
.recent-results-list::-webkit-scrollbar {
  height: 6px;
}

.recent-results-list::-webkit-scrollbar-track {
  background: #1a202c;
}

.recent-results-list::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 3px;
}

.recent-results-list::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

/* Remove number input spinner */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}

/* Navigation-specific styles */
.nav-sub-item {
  padding-left: 2rem;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;
}

.nav-sub-item:hover {
  background-color: #4a5568;
}

/* Ensure the sidebar is scrollable */
.nav-container {
  overflow-y: auto;
}

/* Custom scrollbar for navigation */
.nav-container::-webkit-scrollbar {
  width: 8px;
}

.nav-container::-webkit-scrollbar-track {
  background: #2d3748;
}

.nav-container::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 4px;
}

.nav-container::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

/* Mobile-specific styles */
@media (max-width: 640px) {
  /* Navigation */
  .nav-container {
    z-index: 10001 !important;
    width: 100%;
    max-width: 300px;
  }
  .nav-items {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-items > div {
    width: 100%;
  }
  .nav-sub-items {
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    display: block;
  }
  .nav-sub-item {
    width: 100%;
    padding-left: 2rem;
    font-size: 0.875rem;
  }
  /* Charts */
  .chart-container {
    min-height: 250px;
    max-height: 300px;
  }
}

/* Style for the winners marquee container */
.winners-marquee-container {
  position: sticky;
  bottom: 0;
  background-color: #1f2937;
  padding: 0.5rem 1rem;
  border-top: 1px solid #374151;
}

/* Style for the winners marquee */
.winners-marquee {
  white-space: nowrap;
  overflow: hidden;
}

/* Ensure the marquee items are evenly spaced */
.winners-marquee span {
  display: inline-block;
  margin-right: 1rem;
}

/* Marquee Animation */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: marquee 15s linear infinite;
}

/* Pause animation on hover */
.group:hover .group-hover\:paused {
  animation-play-state: paused;
}

/* Additional styles for better UI consistency */

/* Ensure buttons have a consistent hover effect */
button:hover {
  opacity: 0.9;
}

/* Style for disabled buttons */
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Ensure modals have a consistent backdrop */
.modal-backdrop {
  backdrop-filter: blur(5px);
}

/* Style for toast notifications */
.Toastify__toast {
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

.Toastify__toast--success {
  background: #48bb78 !important;
}

.Toastify__toast--error {
  background: #f56565 !important;
}

.Toastify__toast--info {
  background: #4299e1 !important;
}

/* Ensure inputs have a consistent style */
input {
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
}

/* Style for game section cards */
.game-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-card:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.4);
}

/* Ensure text truncation for long addresses */
.truncate-address {
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style for language switch button */
.language-switch {
  transition: background 0.3s ease;
}

.language-switch:hover {
  background: linear-gradient(to right, #4299e1, #3182ce);
}

/* Style for referral link copy button */
.copy-referral {
  transition: background 0.3s ease;
}

.copy-referral:hover {
  background: #4a5568;
}

/* Ensure consistent spacing for navigation items */
.nav-item {
  margin-bottom: 0.5rem;
}

/* Style for game selection dropdown */
.game-dropdown {
  max-height: 300px;
  overflow-y: auto;
}

.game-dropdown::-webkit-scrollbar {
  width: 6px;
}

.game-dropdown::-webkit-scrollbar-track {
  background: #1a202c;
}

.game-dropdown::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 3px;
}

.game-dropdown::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

/* Highlight the Connect Wallet button */
.connect-wallet-button {
  animation: pulse-attention 2s infinite;
}

@keyframes pulse-attention {
  0% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  }
}

/* Improved error message styling */
.error-message {
  background-color: rgba(229, 62, 62, 0.1); /* Subtle red background */
  color: #e53e3e; /* Softer red text */
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem; /* Smaller font size */
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
}

.error-message:hover {
  opacity: 0.9;
}

.error-message button {
  background: none;
  border: none;
  color: #e53e3e;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0 0.5rem;
}

.error-message button:hover {
  color: #f56565;
}

/* Loading spinner for contract interactions */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.loading-spinner svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}